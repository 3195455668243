<template>
    <div
        class="tooltip-info text-white bg-primary d-inline-flex align-items-center justify-content-center position-relative"
        @focusin="handleFocusIn"
        @focusout="handleFocusOut"
        @click="handleClick"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave">
        <svg viewBox="0 0 16 16" class="w-100 bi bi-info" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"/>
            <circle cx="8" cy="4.5" r="1"/>
        </svg>

        <div class="tooltip"
             :class="{
                'tooltip-show': isVisible,
                'tooltip-noanimation': !animation,
                'bs-tooltip-top': placement == 'top',
                'bs-tooltip-bottom': placement == 'bottom',
                'bs-tooltip-left': placement == 'left',
                'bs-tooltip-right': placement == 'right'
        }"
             role="tooltip">
            <div class="arrow"></div>
            <div class="tooltip-inner bg-primary">
                {{ content }}
            </div>
        </div>

    </div>
</template>

<script>


export default {
    name: "InfoTooltip",
    data: () => ({
        isVisible: false,
    }),
    props: {
        animation: {
            type: Boolean,
            default: true
        },
        delay: {
            type: [Number, Object], // { "show": 500, "hide": 100 }
            default: 0,
        },
        content: String,
        placement: {
            type: String,
            default: 'top',
            validator: (value) => ['top', 'bottom', 'left', 'right'].indexOf(value) !== -1,
        },
        trigger: {
            type: String,
            default: 'hover focus',
            validator: (value) => ['hover focus', 'hover', 'mouseenter', 'mouseleave', 'focus', 'focusin', 'focusout', 'click'].indexOf(value) !== -1,
        }
    },
    computed: {
        castTrigger() {
            const {trigger} = this;

            const tempTrigger = trigger.replace('hover', 'mouseenter mouseleave').replace('focus', 'focusin focusout');

            return tempTrigger.split(' ');
        },
        castDelay() {
            const {delay} = this;
            if (typeof delay === 'number') {
                return {show: delay, hide: delay};
            }
            return delay;
        }
    },
    methods: {
        handleClick({type}) {
            this.toggleShowBYEvent(type);
        },
        handleMouseEnter({type}) {
            this.toggleShowBYEvent(type);
        },
        handleMouseLeave({type}) {
            this.toggleShowBYEvent(type);
        },
        handleFocusIn({type}) {
            this.toggleShowBYEvent(type);
        },
        handleFocusOut({type}) {
            this.toggleShowBYEvent(type);
        },
        toggleShowBYEvent(eventName) {
            const {isVisible, castDelay} = this;
            if (this.castTrigger.indexOf(eventName) > -1) {
                setTimeout(() => this.isVisible = !isVisible, (isVisible ? castDelay.hide : castDelay.show));
            }
        }
    }
}
</script>

<style scoped lang="scss">
.tooltip-info {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    top: 3px;
    cursor: pointer;
}

.bs-tooltip {
    &-top,
    &-bottom {
        .arrow {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &-left,
    &-right {
        .arrow {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &-top {
        bottom: 100%;
        transform: scale(0.75) translateY(19px);

        .arrow:before {
            border-top-color: $primary;
        }
    }

    &-bottom {
        top: 100%;
        transform: scale(0.75) translateY(-19px);

        .arrow:before {
            border-bottom-color: $primary;
        }
    }

    &-left {
        right: 100%;
        transform: scale(0.75) translateX(19px);

        .arrow:before {
            border-left-color: $primary;
        }
    }

    &-right {
        left: 100%;
        transform: scale(0.75) translateX(-19px);

        .arrow:before {
            border-right-color: $primary;
        }
    }
}

.tooltip {
    pointer-events: none;
    min-width: 220px;
    z-index: $zindex-tooltip;

    &:not(.tooltip-noanimation) {
        transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;

    }

    &-show {
        opacity: 1;
        transform: scale(1) translate(0);
        pointer-events: auto;
    }
}
</style>